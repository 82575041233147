import { useState } from "react";
import { Input } from "./ui/input";
import { Checkbox } from "./ui/checkbox";

const ContactList = ({ contacts, selectedContacts, setSelectedContacts }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleContactToggle = (contact) => {
    if (selectedContacts.some((item) => item.email === contact.email)) {
      setSelectedContacts(
        selectedContacts.filter((item) => item.email !== contact.email)
      );
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full p-4">
      <h2 className="text-xl font-semibold mb-4">Contacts</h2>
      <Input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search contacts..."
        className="w-full p-2 mb-4 border rounded-lg"
      />
      <ul className="space-y-4 max-h-96 overflow-y-auto">
        {filteredContacts.map((contact) => (
          <li
            key={contact.id}
            className="p-4 border rounded-lg cursor-pointer bg-white flex items-center space-x-4"
          >
            <Checkbox
              checked={selectedContacts.some(
                (item) => item.email === contact.email
              )}
              onCheckedChange={() => handleContactToggle(contact)}
              className="mr-4"
            />
            <div className="flex-1">
              <h3 className="text-lg font-bold">{contact.userName}</h3>
              <p className="text-gray-600">{contact.email}</p>
              <p className="text-gray-600">
                {contact.position} at {contact.companyName}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactList;
