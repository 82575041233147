import { Button } from "@/components/ui/button";
import { isTokenValid } from "@/lib/auth";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (isTokenValid()) {
      navigate("/dashboard");
    } else {
      navigate("/signin");
    }
  };
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow container mx-auto p-4">
        <section className="text-center py-20">
          <h2 className="text-4xl font-bold mb-4">
            Transform Your Marketing with AI-Driven Solutions
          </h2>
          <p className="text-lg mb-8">
            Revolutionize how you engage with customers using personalized
            AI-powered marketing tools.
          </p>
          <Button
            asChild
            className="bg-[#275546] hover:bg-[#33725E] text-white"
            onClick={handleGetStarted}
          >
            <span className="cursor-pointer">Get Started</span>
          </Button>
        </section>

        <section id="features" className="py-20">
          <h1 className="text-3xl font-bold text-center mb-8">
            Powerful Features to Elevate Your Marketing
          </h1>
          <h2 className="text-3xl font-bold text-center mb-8">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-4">Easy to Use</h3>
              <p>
                Streamline your marketing process with our intuitive,
                user-friendly platform. No technical skills required.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-4">AI-Powered</h3>
              <p>
                Harness the power of AI to create professional, effective
                marketing strategies tailored to your business needs.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-4">Customizable</h3>
              <p>
                Personalize your campaigns and templates to reflect your unique
                brand identity and engage your target audience.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-4">
                Comprehensive Insights
              </h3>
              <p>
                Gain valuable insights into customer behavior and campaign
                performance with our detailed analytics tools.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold mb-4"> Seamless Integration</h3>
              <p>
                Effortlessly integrate with your existing tools and social media
                platforms for a unified marketing experience.
              </p>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="py-20 bg-gray-50">
          <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="text-center p-6">
              <h3 className="text-2xl font-bold mb-4">1. Input Your Data</h3>
              <p>
                Provide details about your company, products, and target
                audience. Our AI will use this information to craft tailored
                marketing strategies.
              </p>
            </div>
            <div className="text-center p-6">
              <h3 className="text-2xl font-bold mb-4">2. Generate Templates</h3>
              <p>
                Use our AI-powered tool to instantly create customized email
                templates and social media posts designed to captivate your
                audience.
              </p>
            </div>
            <div className="text-center p-6">
              <h3 className="text-2xl font-bold mb-4">3. Use and Customize</h3>
              <p>
                Review, edit, and personalize the generated templates to ensure
                they meet your specific needs and brand voice.
              </p>
            </div>
            <div className="text-center p-6">
              <h3 className="text-2xl font-bold mb-4">4. Launch and Track</h3>
              <p>
                Deploy your campaigns across multiple channels and track their
                performance with our integrated analytics.
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer
        className=" p-4"
        style={{ backgroundColor: "#275546", color: "white" }}
      >
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Connectly Wise. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
