import { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { FormLabel } from "./ui/form";
const UploadFile = ({ setUploadedData }) => {
  const [uploadedFileName, setUploadedFileName] = useState(""); // State to hold uploaded file name
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedFileName(file.name); // Set uploaded file name
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      const uploadedData = worksheet.map((row) => ({
        email: row.email,
        userName: row.usernames,
        position: row.positions,
        companyName: row.companynames,
      }));

      setUploadedData(uploadedData);
    };
    reader.readAsArrayBuffer(file);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      {" "}
      <FormLabel className="text-base">Upload File</FormLabel>
      <div {...getRootProps()} className="border-dashed border-2 p-4 mt-4 mb-2">
        <input {...getInputProps()} />
        <p>
          {uploadedFileName
            ? `File uploaded: ${uploadedFileName}`
            : "Drag 'n' drop an Excel file here, or click to select one"}
        </p>
      </div>
    </div>
  );
};

export default UploadFile;
