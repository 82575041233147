import SendEmail from "@/components/send-email";
import { useLoaderData, useNavigation } from "react-router-dom";
const SendEmailPage = () => {
  const result = useLoaderData();
  const navigation = useNavigation();
  if (navigation.state === "loading") {
    return <p className="text-gray-600">Loading...</p>;
  }
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <SendEmail data={result || {}} />
    </div>
  );
};

export default SendEmailPage;
