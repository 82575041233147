import { useForm } from "react-hook-form";
import { useState } from "react";
import axiosInstance from "@/axiosInstance";

export const useGenerateEmailTemplate = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    setError("");
    setSuccess("");
    setIsPending(true);
    try {
      const response = await axiosInstance.post("/generate-email", data);
      const emailTemplate = response.data.emailTemplate;
      // Extract subject and body from the template
      const subjectMatch = emailTemplate.match(/^Subject: (.+)$/m);
      const body = emailTemplate.replace(/^Subject: .+$/m, "").trim();
      setValue("subject", subjectMatch ? subjectMatch[1] : "");
      setValue("body", body);
      setSuccess(response.data.success || "Email generated successfully!");
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsPending(false);
    }
  };

  return {
    isPending,
    error,
    success,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    onSubmit,
    setError,
    setSuccess,
  };
};
