import AuthContext from "@/context/authContext";
import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function MainNavigation() {
  const { token, signOut, userInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut();
    navigate("/");
  };
  return (
    <div className="p-4" style={{ backgroundColor: "#275546", color: "white" }}>
      <div className="flex justify-between items-center max-w-6xl mx-auto p-3">
        <NavLink to="/" className="flex items-center gap-2">
          <img
            src="/connectly-wise-header.png"
            alt="connectly-wise-header"
            className="h-8"
          />
        </NavLink>
        <ul className="flex gap-4">
          <NavLink to="/">
            <li>Home</li>
          </NavLink>
          {userInfo && (
            <NavLink to="dashboard">
              <li>Dashboard</li>
            </NavLink>
          )}
          {!token && (
            <>
              <li>
                <NavLink to="/signin">Sign In</NavLink>
              </li>
              <li>
                <NavLink to="/signup">Sign Up</NavLink>
              </li>
            </>
          )}
          {token && (
            <li>
              <button onClick={handleLogout}>Logout</button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
