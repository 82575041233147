import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import Root from "./pages/root";
import Error from "./pages/error";
import { checkAuthLoader } from "./lib/auth";
import Business from "./pages/business";
import BusinessFormPage from "./pages/businessFormPage";
import { AuthProvider } from "./context/authContext";
import { businessFormsLoader } from "./loaders/businessFormsLoader";
import { emailTemplateLoader } from "./loaders/emailTemplateLoader";
import FormPage from "./pages/formPage";
import GenerateEmailPage from "./pages/generateEmailPage";
import ManageContactsPage from "./pages/manageContactsPage";
import SendEmailPage from "./pages/sendEmailPage";
import { sendEmailLoader } from "./loaders/sendEmailLoader";
import Dashboard from "./pages/dashboard";
import { contactFormLoader } from "./loaders/contactFormLoader";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import GenerateCaptionsPage from "./pages/generateCaptionsPage";
import HelmetMetaData from "./components/helmet-data-props";
import SubscriptionForm from "./components/subscription-form";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { subscriptionFormLoader } from "./loaders/subscriptionFormLoader";
import { fbAccessTokenLoader } from "./loaders/fbAccessTokenLoader";

const stripePromise = loadStripe(
  "pk_test_51Lg5xYDr04p5KIkaOWFNT1Y8OtYIfwsNxuCJz2HDkDE19UdKedT6ciQ1p3P0xpb5JCn4u6nGWHpudzPFkL38tVgE00AoVzt9er"
);
// Define a component to wrap around HelmetMetaData
const MetaWrapper = (props) => (
  <>
    <HelmetMetaData {...props} />
    {props.children}
  </>
);
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MetaWrapper>
        <Root />
      </MetaWrapper>
    ),
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "businessforms",
        element: <Business />,
        loader: businessFormsLoader,
      },

      {
        path: "dashboard",
        element: <Dashboard />, // Nested routes are defined here
        children: [
          { path: "generate-email", element: <GenerateEmailPage /> },
          {
            path: "generate-captions",
            element: <GenerateCaptionsPage />,
            loader: fbAccessTokenLoader,
          },
          {
            path: "manage-contacts",
            element: <ManageContactsPage />,
            loader: contactFormLoader,
          },
          {
            path: "send-emails",
            element: <SendEmailPage />,
            loader: sendEmailLoader,
          },
          { path: "form", element: <FormPage />, loader: businessFormsLoader },
          {
            path: "subscriptionForm",
            element: (
              <Elements stripe={stripePromise}>
                <SubscriptionForm />
              </Elements>
            ),
            loader: subscriptionFormLoader,
          },
          { index: true, element: <FormPage />, loader: businessFormsLoader }, // Default route
        ],
      },
      {
        path: "form/:formId",
        element: <BusinessFormPage />,
        loader: emailTemplateLoader,
      },
      {
        path: "signin",
        element: <SignIn />,
        loader: checkAuthLoader,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
        loader: checkAuthLoader,
      },
      {
        path: "signup",
        element: <SignUp />,
        loader: checkAuthLoader,
      },
    ],
  },
]);
const App = () => {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;
