import AuthContext from "@/context/authContext";
import { useContext, useEffect, useState } from "react";
import { useLoaderData, useNavigation } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { CardWrapper } from "./card-wrapper";
import { FormError } from "./form-error";
import axiosInstance from "@/axiosInstance";

const SubscriptionForm = () => {
  const result = useLoaderData();
  const navigation = useNavigation();
  const { userInfo, updateUser } = useContext(AuthContext);
  const [fbAccessToken, setFbAccessToken] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [selectedPageName, setSelectedPageName] = useState("");
  const [errorButton, setErrorButton] = useState(null);

  useEffect(() => {
    if (result) {
      updateUser(result);
      if (result.fbAccessToken) {
        setFbAccessToken(result.fbAccessToken);
      }
      if (result.selectedPageID) {
        setSelectedPageId(result.selectedPageID);
      }
    }
  }, [result, updateUser]);

  useEffect(() => {
    const fetchPageName = async () => {
      if (selectedPageId && fbAccessToken) {
        try {
          const pageResponse = await axios.get(
            `https://graph.facebook.com/v20.0/${selectedPageId}?fields=name&access_token=${fbAccessToken}`
          );
          setSelectedPageName(pageResponse.data.name);
        } catch (error) {
          setErrorButton("Failed to fetch page name");
        }
      }
    };
    fetchPageName();
  }, [selectedPageId, fbAccessToken]);

  if (navigation.state === "loading") {
    return <p className="text-gray-600">Loading...</p>;
  }

  const saveTokensToBackend = async (
    fbAccessToken,
    selectedPageID,
    pageAccessToken
  ) => {
    try {
      await axiosInstance.post("/saveTokens", {
        fbAccessToken,
        selectedPageID,
        pageAccessToken,
      });
    } catch (error) {
      console.error("Failed to save tokens", error);
    }
  };

  const handleFacebookLogin = async (response) => {
    if (response.accessToken) {
      try {
        // Exchange short-lived user token for long-lived token
        const longLivedTokenResponse = await axios.get(
          `https://graph.facebook.com/v20.0/oauth/access_token`,
          {
            params: {
              grant_type: "fb_exchange_token",
              client_id: "512031271378962",
              client_secret: "780285c132735023f31a4bb0f0013aeb",
              fb_exchange_token: response.accessToken,
            },
          }
        );
        const longLivedUserAccessToken =
          longLivedTokenResponse.data.access_token;
        setFbAccessToken(longLivedUserAccessToken);

        // Fetch pages using long-lived user access token
        const pagesResponse = await axios.get(
          `https://graph.facebook.com/v20.0/me/accounts?access_token=${longLivedUserAccessToken}`
        );
        setPages(pagesResponse.data.data);

        // Save the long-lived user access token to the backend
        await saveTokensToBackend(longLivedUserAccessToken, null, null);
      } catch (error) {
        setErrorButton("Failed to fetch Facebook pages");
      }
    } else {
      setErrorButton("Failed to log in to Facebook");
    }
  };

  const handlePageSelect = async (event) => {
    const pageID = event.target.value;
    if (pageID) {
      try {
        const tokenResponse = await axios.get(
          `https://graph.facebook.com/v20.0/${pageID}?fields=access_token&access_token=${fbAccessToken}`
        );

        // Save the selected page ID and page access token to the backend
        await saveTokensToBackend(
          fbAccessToken,
          pageID,
          tokenResponse.data.access_token
        );
        setSelectedPageId(pageID);
        setSelectedPageName(
          pages.find((page) => page.id === pageID)?.name || ""
        );
      } catch (error) {
        setErrorButton("Failed to fetch page access token");
      }
    }
  };

  return (
    <>
      {result && result.subscriptionStatus === "SUBSCRIBED" ? (
        <div className="container mx-auto p-4 flex justify-center items-center">
          <CardWrapper
            headerLabel="Please Login with Facebook and select your Facebook page"
            headerText="Facebook Login"
            backButtonLabel="Go Back"
            backButtonHref="/"
            className="w-[1500px] shadow-md"
          >
            <div className="flex justify-center items-center space-x-4">
              {!fbAccessToken ? (
                <FacebookLogin
                  appId="512031271378962"
                  fields="name,email,picture"
                  scope="public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts"
                  callback={handleFacebookLogin}
                />
              ) : (
                <div className="space-y-4">
                  {selectedPageId ? (
                    <p>
                      You have selected the Facebook page: {selectedPageName}
                    </p>
                  ) : (
                    <select
                      onChange={handlePageSelect}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select a Page</option>
                      {pages.map((page) => (
                        <option key={page.id} value={page.id}>
                          {page.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
            </div>
            <div className="mt-4 mb-2">
              <FormError message={errorButton} className="mt-4 mb-2" />
            </div>
          </CardWrapper>
        </div>
      ) : (
        <stripe-pricing-table
          pricing-table-id="prctbl_1PiOp3Dr04p5KIkaBqLgTgOx"
          publishable-key="pk_test_51Lg5xYDr04p5KIkaOWFNT1Y8OtYIfwsNxuCJz2HDkDE19UdKedT6ciQ1p3P0xpb5JCn4u6nGWHpudzPFkL38tVgE00AoVzt9er"
          client-reference-id={userInfo.id}
        ></stripe-pricing-table>
      )}
    </>
  );
};

export default SubscriptionForm;
