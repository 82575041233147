import BusinessDetailForm from "@/components/business-detail-form";
import { useLoaderData, useNavigation, useParams } from "react-router-dom";

const FormPage = () => {
  const result = useLoaderData();
  const navigation = useNavigation();
  const { formId } = useParams();
  if (navigation.state === "loading") {
    return (
      <div className="container mx-auto p-4 flex justify-center items-center">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }
  const hasSubmittedForm = result && Object.keys(result).length > 0;
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      {hasSubmittedForm ? (
        <>
          <BusinessDetailForm formData={result[0]} formId={result[0].id} />
        </>
      ) : (
        <BusinessDetailForm formData={{}} formId={formId} />
      )}
    </div>
  );
};

export default FormPage;
