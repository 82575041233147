import ManageContacts from "@/components/manage-contacts";
import { useLoaderData, useNavigation } from "react-router-dom";

const ManageContactsPage = () => {
  const result = useLoaderData();
  const navigation = useNavigation();
  if (navigation.state === "loading") {
    return (
      <div className="container mx-auto p-4 flex justify-center items-center">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <ManageContacts contacts={result.contacts || []} />
    </div>
  );
};

export default ManageContactsPage;
