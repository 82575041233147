import axiosInstance from "@/axiosInstance";

export const contactFormLoader = async () => {
  try {
    const response = await axiosInstance.get("/get-contacts");
    return response.data;
  } catch (error) {
    console.error("Error fetching business forms:", error);
  }
};
