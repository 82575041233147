import axiosInstance from "@/axiosInstance";

export const subscriptionFormLoader = async () => {
  try {
    const response = await axiosInstance.get("/userInfo");
    return response.data.user;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};
