import axiosInstance from "@/axiosInstance";
export const createImageCaption = async (data) => {
  try {
    const response = await axiosInstance.post("/generate-caption", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return { error: error.message };
  }
};
