import { Button } from "./ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Textarea } from "./ui/textarea";
import { FormError } from "./form-error";
import { FormSuccess } from "./form-success";
import { CardWrapper } from "./card-wrapper";
import { useGenerateEmailTemplate } from "@/hooks/useGenerateEmailTemplate";
import axiosInstance from "@/axiosInstance";
import { useState } from "react";

const GenerateEmail = () => {
  const [errorButton, setErrorButton] = useState(null);
  const [successButton, setSuccessButton] = useState(null);
  const {
    isPending,
    error,
    success,
    handleSubmit,
    errors,
    control,
    onSubmit,
    getValues,
  } = useGenerateEmailTemplate();
  const handleSave = async (event) => {
    event.preventDefault();
    const { subject, body } = getValues();
    try {
      await axiosInstance.post("/save-email-template", {
        subject,
        body,
      });
      setSuccessButton("Email Template Saved Successfully");
    } catch (err) {
      setErrorButton("An unexpected error occurred. Please try again.");
    }
  };
  return (
    <CardWrapper
      headerLabel="Enter Email Prompt"
      headerText="Please enter details regarding email, so we can AI it up"
      backButtonLabel="Go Back"
      backButtonHref="/"
      className="w-[1500px] shadow-md"
    >
      <div className="container mx-auto p-4">
        <Form {...control}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={control}
              name="emailprompt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Prompt:</FormLabel>
                  <FormControl>
                    <Textarea {...field} id="emailprompt" className="h-32" />
                  </FormControl>
                  <FormMessage>{errors.emailprompt?.message}</FormMessage>
                </FormItem>
              )}
              rules={{ required: "Email Prompt is required" }}
            />
            <FormError message={error} className="mt-4 mb-2" />
            <FormSuccess message={success} className="mt-4 mb-2" />
            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
                disabled={isPending}
              >
                Generate Email Template
              </Button>
            </div>
            {success && (
              <div className="mt-6 space-y-6">
                <FormField
                  control={control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Subject:</FormLabel>
                      <FormControl>
                        <Textarea {...field} id="subject" />
                      </FormControl>
                      <FormMessage>{errors.subject?.message}</FormMessage>
                    </FormItem>
                  )}
                  rules={{ required: "Email Subject is required" }}
                />
                <FormField
                  control={control}
                  name="body"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Body:</FormLabel>
                      <FormControl>
                        <Textarea {...field} id="body" className="h-64" />
                      </FormControl>
                      <FormMessage>{errors.body?.message}</FormMessage>
                    </FormItem>
                  )}
                  rules={{ required: "Message Body is required" }}
                />
                <FormError message={errorButton} className="mt-4 mb-2" />
                <FormSuccess message={successButton} className="mt-4 mb-2" />
                <div className="flex justify-end space-x-4">
                  <Button
                    onClick={handleSave}
                    className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
                  >
                    Save Email Template
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
      </div>
    </CardWrapper>
  );
};

export default GenerateEmail;
