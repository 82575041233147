import GenerateEmail from "@/components/generate-email";

const GenerateEmailPage = () => {
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <GenerateEmail />
    </div>
  );
};

export default GenerateEmailPage;
