import Sidebar from "@/components/sidebar";
import AuthContext from "@/context/authContext";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const { userInfo } = useContext(AuthContext);
  const hasActiveSubscriptionAndCompletedBusinessForm =
    userInfo?.businessForm && userInfo.subscriptionStatus === "SUBSCRIBED";
  return (
    <div className="flex overflow-hidden">
      <Sidebar isSubscribed={hasActiveSubscriptionAndCompletedBusinessForm} />
      <div className="flex-grow p-4 overflow-y-auto">
        <Outlet /> {/* Render nested routes here */}
      </div>
    </div>
  );
};

export default Dashboard;
