import axiosInstance from "@/axiosInstance";

export const emailTemplateLoader = async ({ params }) => {
  try {
    const response = await axiosInstance.get(`/businessform/${params.formId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching business forms:", error);
  }
};
