import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { createBusinessForm } from "@/api/createBusinessForm";
import { updateBusinessForm } from "@/api/updateBusinessForm";
import AuthContext from "@/context/authContext";

export const useBusinessDetailForm = ({ formId }) => {
  const { updateUserInfo } = useContext(AuthContext);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      email: "",
      primaryColor: "",
      secondaryColor: "",
      typeOfBusiness: "",
      description: "",
      targetAudience: "",
      emailStyle: "",
      logo: null,
    },
  });
  const onSubmit = async (data) => {
    setError("");
    setSuccess("");
    setIsPending(true);
    try {
      const submitFunction = formId ? updateBusinessForm : createBusinessForm;
      const response = await submitFunction(data, formId);
      if (response.error) {
        setError(response.error);
      } else if (response.success) {
        setSuccess(response.success);
        updateUserInfo(response.businessForm); // Update context
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsPending(false);
    }
  };

  return {
    isPending,
    error,
    success,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    onSubmit,
    setError,
    setSuccess,
  };
};
