import { Button } from "./ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Textarea } from "./ui/textarea";
import { FormError } from "./form-error";
import { FormSuccess } from "./form-success";
import { CardWrapper } from "./card-wrapper";
import { useState } from "react";
import LogoUpload from "./logo-upload";
import { useGenerateImageCaption } from "@/hooks/useGenerateImageCaption";
import axiosInstance from "@/axiosInstance";
import { useLoaderData, useNavigation } from "react-router-dom";

const GenerateCaptions = () => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [errorButton, setErrorButton] = useState(null);
  const [successButton, setSuccessButton] = useState(null);
  const {
    isPending,
    error,
    success,
    handleSubmit,
    errors,
    control,
    onSubmit,
    getValues,
    setValue,
    setError,
  } = useGenerateImageCaption();
  const result = useLoaderData();
  const { fbAccessToken, selectedPageID, pageAccessToken } = result.user;
  const navigation = useNavigation();
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB in bytes

  if (navigation.state === "loading") {
    return (
      <div className="container mx-auto p-4 flex justify-center items-center">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  const handlePostOnFacebook = async (event) => {
    event.preventDefault();
    if (!pageAccessToken) {
      setErrorButton(
        "You need to login on Facebook and select a Facebook page first. Please go to the settings page first."
      );
      return;
    }
    const formData = new FormData();
    formData.append("image", getValues("image"));
    formData.append("caption", getValues("caption"));
    formData.append("accessToken", fbAccessToken);
    formData.append("pageID", selectedPageID);
    formData.append("pageAccessToken", pageAccessToken);
    try {
      const res = await axiosInstance.post("/postToFacebook", formData);
      console.log("Facebook posting response", res);
      setSuccessButton("Successfully posted on Facebook");
    } catch (error) {
      console.log("Facebook Posting", error);
      setErrorButton("Failed to post on Facebook");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(
          "File size exceeds the 1 MB limit. Please upload a smaller file."
        );
        setPreviewUrl(null);
        setValue("image", null); // Clear form value for image if file is too large
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setValue("image", file); // Set form value for image
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
      setValue("image", null); // Clear form value for image if no file selected
    }
  };

  const handleRemoveFile = () => {
    setPreviewUrl(null);
    setValue("image", null); // Clear form value for image if no file selected
  };

  return (
    <CardWrapper
      headerLabel="Enter Image Prompt"
      headerText="Please enter details regarding the image, so we can AI it up"
      backButtonLabel="Go Back"
      backButtonHref="/"
      className="w-[1500px] shadow-md"
    >
      <div className="container mx-auto p-4">
        <Form {...control}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={control}
              name="image"
              render={() => (
                <FormItem>
                  <FormLabel>Image (Max size: 1 MB)</FormLabel>
                  <LogoUpload
                    handleFileChange={handleFileChange}
                    previewUrl={previewUrl}
                    handleRemoveFile={handleRemoveFile}
                  />
                  <FormMessage>{errors.image?.message}</FormMessage>
                </FormItem>
              )}
              rules={{ required: "Image is required" }}
            />
            <FormField
              control={control}
              name="imageprompt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Image Prompt:</FormLabel>
                  <FormControl>
                    <Textarea {...field} id="imageprompt" className="h-32" />
                  </FormControl>
                  <FormMessage>{errors.imageprompt?.message}</FormMessage>
                </FormItem>
              )}
              rules={{ required: "Image Prompt is required" }}
            />
            <FormError message={error} className="mt-4 mb-2" />
            <FormSuccess message={success} className="mt-4 mb-2" />
            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
                disabled={isPending}
              >
                Generate Image Caption
              </Button>
            </div>
            {success && (
              <div className="mt-6 space-y-6">
                <FormField
                  control={control}
                  name="caption"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Image Caption:</FormLabel>
                      <FormControl>
                        <Textarea {...field} id="caption" className="h-32" />
                      </FormControl>
                      <FormMessage>{errors.caption?.message}</FormMessage>
                    </FormItem>
                  )}
                  rules={{ required: "Image Caption is required" }}
                />
                <FormError message={errorButton} className="mt-4 mb-2" />
                <FormSuccess message={successButton} className="mt-4 mb-2" />

                <div className="flex justify-end">
                  <Button
                    onClick={handlePostOnFacebook}
                    className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
                  >
                    Post on Facebook
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
      </div>
    </CardWrapper>
  );
};

export default GenerateCaptions;
