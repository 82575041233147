import { NavLink } from "react-router-dom";
import ColorPicker from "./color-picker";
import { CardWrapper } from "./card-wrapper";

const BusinessList = ({ businessForms }) => {
  return (
    <CardWrapper
      headerLabel="Saved Business Forms"
      headerText="Business Forms"
      backButtonLabel="Go Back"
      backButtonHref="/"
      className="w-[1500px] shadow-md"
    >
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-[#33725E] text-white">
            <tr>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Primary Color</th>
              <th className="py-3 px-6 text-left">Secondary Color</th>
              <th className="py-3 px-6 text-left">Type of Business</th>
              <th className="py-3 px-6 text-left">Email Style</th>
              <th className="py-3 px-6 text-left">Logo</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {businessForms.map((form) => (
              <tr
                key={form.id}
                className="hover:bg-gray-100 transition-colors cursor-pointer"
              >
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>{form.name}</NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>{form.email}</NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>
                    <ColorPicker color={form.primaryColor} />
                  </NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>
                    <ColorPicker color={form.secondaryColor} />
                  </NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>
                    {form.typeOfBusiness}
                  </NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>{form.emailStyle}</NavLink>
                </td>
                <td className="py-4 px-6">
                  <NavLink to={`/form/${form.id}`}>
                    {form.logo && (
                      <img
                        src={form.logo}
                        alt={form.name}
                        className="h-10 w-10 rounded-full"
                      />
                    )}
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CardWrapper>
  );
};

export default BusinessList;
