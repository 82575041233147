import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://sc.code-cook.com/api", // Updated URL
  // baseURL: "http://localhost:3000/api",
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Or wherever you store your token

    if (
      token &&
      !["/login", "/register", "/forgot-password"].includes(config.url)
    ) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
