import { NavLink } from "react-router-dom";

const Sidebar = ({ isSubscribed }) => {
  return (
    <aside className="w-64 bg-gray-800 text-white min-h-screen ">
      <div className="p-4">
        <h2 className="text-lg font-bold">Menu</h2>
        <nav className="mt-4">
          {isSubscribed ? (
            <>
              <NavLink
                to="/dashboard/generate-email"
                className={({ isActive }) =>
                  `block px-4 py-2 mt-2 rounded ${
                    isActive ? "bg-gray-700" : "hover:bg-gray-600"
                  }`
                }
              >
                Generate Email
              </NavLink>
              <NavLink
                to="/dashboard/generate-captions"
                className={({ isActive }) =>
                  `block px-4 py-2 mt-2 rounded ${
                    isActive ? "bg-gray-700" : "hover:bg-gray-600"
                  }`
                }
              >
                Generate Captions
              </NavLink>
              <NavLink
                to="/dashboard/manage-contacts"
                className={({ isActive }) =>
                  `block px-4 py-2 mt-2 rounded ${
                    isActive ? "bg-gray-700" : "hover:bg-gray-600"
                  }`
                }
              >
                Manage Contacts
              </NavLink>
              <NavLink
                to="/dashboard/send-emails"
                className={({ isActive }) =>
                  `block px-4 py-2 mt-2 rounded ${
                    isActive ? "bg-gray-700" : "hover:bg-gray-600"
                  }`
                }
              >
                Send Emails
              </NavLink>
            </>
          ) : (
            <p>
              Please complete your payment subscription and business form before
              you can proceed to all features .
            </p>
          )}
          <NavLink
            to="/dashboard/form"
            className={({ isActive }) =>
              `block px-4 py-2 mt-2 rounded ${
                isActive ? "bg-gray-700" : "hover:bg-gray-600"
              }`
            }
          >
            Business Settings
          </NavLink>
          <NavLink
            to="/dashboard/subscriptionForm"
            className={({ isActive }) =>
              `block px-4 py-2 mt-2 rounded ${
                isActive ? "bg-gray-700" : "hover:bg-gray-600"
              }`
            }
          >
            Settings
          </NavLink>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
