import axiosInstance from "@/axiosInstance";

export const fbAccessTokenLoader = async () => {
  try {
    const response = await axiosInstance.get("/getTokens");
    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};
