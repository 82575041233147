import { useEffect, useState } from "react";
import { CardWrapper } from "./card-wrapper";
import {
  Form,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import UploadFile from "./upload-file";
import { useSaveContactsForm } from "@/hooks/useSaveContactForm";
import { FormError } from "./form-error";
import { FormSuccess } from "./form-success";
import { Button } from "./ui/button";
const ManageContacts = ({ contacts: savedContacts }) => {
  const [uploadedData, setUploadedData] = useState([]);
  const {
    isPending,
    error,
    success,
    handleSubmit,
    errors,
    control,
    onSubmit,
    setValue, // use setValue to programmatically set form values
  } = useSaveContactsForm();
  useEffect(() => {
    setValue("contacts", uploadedData);
  }, [uploadedData, setValue]);
  return (
    <CardWrapper
      headerLabel="Send Email"
      headerText="Preview Email Template"
      backButtonLabel="Go Back"
      backButtonHref="/"
      className="w-[1500px] shadow-md"
    >
      <div className="container mx-auto p-4">
        <Form {...control}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <UploadFile setUploadedData={setUploadedData} />
            <FormItem>
              <div className="mb-4">
                <FormLabel className="text-base">Extracted Contacts</FormLabel>
                <FormDescription>
                  List of extracted contacts from file
                </FormDescription>
              </div>
              {uploadedData.map((item) => (
                <FormItem key={item}>
                  <div className="flex flex-row items-start space-x-3 space-y-0">
                    <div className="flex flex-row items-center space-x-3">
                      <FormLabel className="font-normal">
                        {item.email}
                      </FormLabel>
                      <FormDescription>
                        {item.userName}, {item.position} at {item.companyName}
                      </FormDescription>
                    </div>
                  </div>
                </FormItem>
              ))}

              <FormMessage />
            </FormItem>
            <FormItem>
              <div className="mb-4">
                <FormLabel className="text-base">Saved Contacts</FormLabel>
                <FormDescription>
                  List of already saved contacts
                </FormDescription>
              </div>
              {savedContacts?.map((item) => (
                <FormItem key={item}>
                  <div className="flex flex-row items-start space-x-3 space-y-0">
                    <div className="flex flex-row items-center space-x-3">
                      <FormLabel className="font-normal">
                        {item.email}
                      </FormLabel>
                      <FormDescription>
                        {item.userName}, {item.position} at {item.companyName}
                      </FormDescription>
                    </div>
                  </div>
                </FormItem>
              ))}

              <FormMessage />
            </FormItem>
            <FormError message={error} className="mt-4 mb-2" />
            <FormSuccess message={success} className="mt-4 mb-2" />
            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
                disabled={isPending}
              >
                Save Contacts
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </CardWrapper>
  );
};

export default ManageContacts;
