import GenerateCaptions from "@/components/generate-captions";

const GenerateCaptionsPage = () => {
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <GenerateCaptions />
    </div>
  );
};

export default GenerateCaptionsPage;
