import { useState } from "react";
import EmailTemplateList from "./emailTemplateList";
import ContactList from "./contactList";
import { CardWrapper } from "./card-wrapper";
import { Button } from "./ui/button";
import axiosInstance from "@/axiosInstance";
import { FormError } from "./form-error";
import { FormSuccess } from "./form-success";

const SendEmail = ({ data }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
    const template = data.emailTemplates.find((t) => t.id === templateId);
    setSubject(template.subject);
    setBody(template.body);
  };
  const handleSendEmails = async () => {
    try {
      const replaceVariables = (template, contact) => {
        return template
          .replace(/\[Recipient's Company Name\]/g, contact.companyName)
          .replace(/\[Recipient's Name\]/g, contact.userName)
          .replace(/\[Recipient's Email\]/g, contact.email)
          .replace(/\[Recipient's Position\]/g, contact.position);
      };

      const personalizedEmails = selectedContacts
        .map((contact) => {
          const personalizedSubject = replaceVariables(subject, contact);
          const personalizedBody = replaceVariables(body, contact);

          // Check if any placeholder was not replaced
          if (
            personalizedSubject.includes("[") ||
            personalizedBody.includes("[")
          ) {
            console.error("Error: Some placeholders were not replaced.");
            return null;
          }

          return {
            email: contact.email,
            subject: personalizedSubject,
            body: personalizedBody,
          };
        })
        .filter((email) => email !== null); // Filter out null entries

      if (personalizedEmails.length === 0) {
        alert("No emails to send. Check for errors in placeholders.");
        return;
      }

      const response = await axiosInstance.post("/send-emails", {
        personalizedEmails,
      });

      if (response.data.error) {
        setError(response.data.error);
      } else if (response.data.success) {
        setSuccess(response.data.success);
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("An error occurred while sending emails.");
    }
  };

  return (
    <CardWrapper
      headerLabel="Please Select Details"
      headerText="Email Sender"
      backButtonLabel="Go Back"
      backButtonHref="/"
      className="w-[1500px] shadow-md"
    >
      <EmailTemplateList
        templates={data.emailTemplates}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={handleTemplateSelect}
      />
      {selectedTemplate && (
        <div className="mt-8">
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Body</label>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              className="w-full p-2 border rounded-lg h-48"
            />
          </div>
        </div>
      )}
      <ContactList
        contacts={data.contacts}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />
      <FormError message={error} />
      <FormSuccess message={success} />
      <div className="mt-8 flex justify-end">
        <Button
          onClick={handleSendEmails}
          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#275546] hover:bg-[#33725E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B836E]"
          disabled={!selectedTemplate || selectedContacts.length === 0}
        >
          Send Emails
        </Button>
      </div>
    </CardWrapper>
  );
};

export default SendEmail;
