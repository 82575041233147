const EmailTemplateList = ({
  templates,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  return (
    <div className="w-full p-4">
      <h2 className="text-xl font-semibold mb-4">Select Email Template</h2>
      <select
        value={selectedTemplate || ""}
        onChange={(e) => setSelectedTemplate(Number(e.target.value))}
        className="w-full p-2 border rounded-lg"
      >
        <option value="" disabled>
          Select an email template
        </option>
        {templates.map((template) => (
          <option key={template.id} value={template.id}>
            {template.subject}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EmailTemplateList;
